<template>
  <HXContentCard title="Lista de Personas">
    <div class="text-right px-4 mt-3">
      <button class="mx-1 btn btn-primary" @click="$router.push({ name: 'Persona_agregar' })">
        <i class="fas fa-plus-circle"></i>
        Agregar Persona
      </button>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table id="datatable_persona" class="table table-bordered table-responsive-md table-striped text-center">
        <thead>
          <tr>
            <th>Numero Documento</th>
            <th>Nombre</th>
            <th>Contraseña reportes</th>
            <th>Cargo</th>
            <th>Estado</th>
            <th>Detalles</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listaItems" :key="item.id">
            <td>{{ item.numeroDocumento }}</td>
            <!-- <td><img class="avatar-35 rounded" src="https://www.w3schools.com/w3images/avatar2.png" alt=""></td> -->
            <td>
              {{
                item.nombres +
                " " +
                (item.segundoNombre != null ? item.segundoNombre : "") +
                " " +
                item.apellidos +
                " " +
                (item.segundoApellido != null ? item.segundoApellido : "")
              }}
            </td>
            <td>{{ item.contrasenaReportes }}</td>
            <td>{{ obtenerUbicacionPersona(item) }}</td>
            <td>
              <VueToggles height="20" width="50" :value="item.estado ? true : false"
                @click="TogglePersonaEstado(item.id)" />
            </td>
            <td>
              <b-button v-b-modal.modal-persona @click="infoForPerson(item)">
                <i class="fas fa-eye mx-auto"></i>
              </b-button>
            </td>
            <td style="width: 20%">
              <div class="d-flex justify-content-center px-0 mx-0">
                <vs-button v-b-tooltip="{
                  title: `Ingreso / Retiro`,
                  placement: 'top',
                  customClass: 'font_tooltip',
                }" style="background-color: #f4c000" warn icon v-b-modal.modal-retiro @click="infoForPerson(item)">
                  <i class="fas fa-solid fa-check"></i>
                </vs-button>
                <vs-button v-b-tooltip="{
                  title: `Registro novedades`,
                  placement: 'top',
                  customClass: 'font_tooltip',
                }" style="background-color: #6f8ac4" success icon @click="gotoRegistroNovedades(item)">
                  <i class="fas fa-solid fa-check"></i>
                </vs-button>
                <vs-button v-b-tooltip="{
                  title: `Hoja de vida`,
                  placement: 'top',
                  customClass: 'font_tooltip',
                }" icon @click="$router.push({ name: 'hoja_vida', params: { id: item.id } })">
                  <i class="fas fa-solid fa-file"></i>
                </vs-button>
                <vs-button v-b-tooltip="{
                  title: `historia laboral`,
                  placement: 'top',
                  customClass: 'font_tooltip',

                }" warn icon @click="$router.push({ name: 'Persona_historial_laboral', params: { id: item.id } })">
                  <i class="fas fa-solid fa-clock"></i>
                </vs-button>
                <vs-button v-b-tooltip="{
                  title: `Editar`,
                  placement: 'top',
                  customClass: 'font_tooltip',
                }" success icon @click="$router.push({ name: 'Persona_editar', params: { id: item.id } })">
                  <i class="fas fa-solid fa-pen"></i>
                </vs-button>
                <vs-button v-b-tooltip="{
                  title: `Gestionar accesos`,
                  placement: 'top',
                  customClass: 'font_tooltip',
                }" v-if="persona.rol.nivel == 4 || persona.rol.nivel == 2" icon danger
                  @click="$router.push({ name: 'ControlAccesosPersona', params: { PersonaId: item.id } })">
                  <i class="fas fa-solid fa-lock"></i>
                </vs-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal id="modal-persona" title="Detalles Persona" size="lg" hide-footer>
      <div class="row">
        <div class="col-12">
          <h3>
            <strong> Informacion Personal </strong>
          </h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span>
              <strong> Nombres </strong> <br />
              {{
                infoForPersonData.nombres +
                " " +
                (infoForPersonData.segundoNombre != null
                  ? infoForPersonData.segundoNombre
                  : "")
              }}
            </span>
            <span>
              <strong> Apellidos </strong> <br />
              {{
                infoForPersonData.apellidos +
                " " +
                (infoForPersonData.segundoApellido != null
                  ? infoForPersonData.segundoApellido
                  : "")
              }}
            </span>
            <span>
              <strong>Tipo de Documento</strong> <br />
              {{ infoForPersonData.tipoDoc }}</span>
            <span>
              <strong> Numero de Documento </strong> <br />
              {{ infoForPersonData.doc }}</span>
            <span>
              <strong> Estado Civil </strong> <br />
              {{ infoForPersonData.estadoCivil }}</span>
          </div>
        </div>

        <div class="col-12 mt-2">
          <h3>
            <strong> Contacto </strong>
          </h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span>
              <strong> Direccion </strong> <br />
              {{ infoForPersonData.direccion }}</span>
            <span>
              <strong> Municipio </strong> <br />
              {{ infoForPersonData.municipio }}</span>
            <span>
              <strong> Telefono Fijo </strong> <br />
              {{ infoForPersonData.telFijo }}</span>
            <span>
              <strong>Telefono Movil</strong> <br />
              {{ infoForPersonData.telMovil }}</span>
          </div>
        </div>
        <div class="col-12 mt-2">
          <h3><strong>Seguridad</strong></h3>
          <span class="mt-2">
            <strong> Correo </strong> <br />
            {{ infoForPersonData.correo }}</span>
        </div>

        <div class="col-12 mt-2">
          <h3><strong> Empresa </strong></h3>
          <div class="d-flex flex-wrap mt-2" style="gap: 1rem">
            <span><strong> Rol en Empresa </strong> <br />
              {{ infoForPersonData.rol }}</span>
            <span>
              <strong> Empresa </strong> <br />
              {{ infoForPersonData.empresa }}</span>
            <span>
              <strong> Sucursal </strong> <br />{{
                infoForPersonData.sucursal
              }}</span>
            <span>
              <strong> Area </strong> <br />
              {{ infoForPersonData.area }}</span>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- modal ingreso retiro -->
    <b-modal id="modal-retiro" title="Ingreso / Retiro" size="md" hide-footer>
      <form ref="formIngresoRetiro" @submit.prevent="guardarIngresoRetiro()">
        <div>
          <strong> Usuario </strong>
          <br />
          <span>
            {{ infoForPersonData.nombres }}
            {{ infoForPersonData.segundoNombre ?? "" }}
            {{ infoForPersonData.apellidos }}
            {{ infoForPersonData.segundoApellido ?? "" }}
          </span>
        </div>
        <div class="row" v-for="itn of NPerFechaIngRet" :key="itn.id">
          <div class="col-12 bg-dark" style="border-radius: 10px" v-if="itn.tipo == 'Ingreso'">
            <div class="d-flex pt-2 px-1">
              <i class="fa-solid fa-calendar-plus h3"></i>
              <div class="pl-2">
                <span class="pb-0 mb-0 text-light mt-3" style="font-size: 13px">Fecha contratación:</span>
                <b class="">
                  {{
                    $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY")
                  }}
                </b>
              </div>
            </div>
          </div>
          <div class="col-12 bg-dark mt-2" style="border-radius: 10px" v-if="itn.tipo != 'Ingreso'">
            <div class="d-flex pt-2 px-1">
              <i class="fa-regular fa-calendar-minus h3"></i>
              <div class="pl-2">
                <span class="pb-0 mb-0 text-light" style="font-size: 13px">Fecha retiro:</span>
                <b v-if="infoForPersonData.fechaRetiro != null" class=" ">
                  {{
                    $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY")
                  }}
                </b>
                <b v-else class=" "> -- -- -- </b>
                <div class="d-flex">
                  <div data-v-6fc0398c="" class="my-1 mx-1" style="
                      width: 7px;
                      height: 7px;
                      border-radius: 50%;
                      background-color: red;
                    "></div>
                  <span class="d-flex">{{
                    listaCausalRetiro.filter(
                      (x) => (x.id = itn.perCausalRetiroId)
                    )[0].causal
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="infoForPersonData.fechaRetiro == null" class="mt-2">
          <label class="m-0 pl-1 pb-1" for="">Causal de retiro</label>
          <b-form-select required v-model="selected" value-field="id" text-field="causal" :options="listaCausalRetiro">
            <template #first>
              <b-form-select-option selected :value="null" disabled>-- selecciona una causal --</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <vs-button type="submit" primary icon class="px-2">
            <i class="fa-solid fa-floppy-disk mr-1"></i>
            Guardar
          </vs-button>
        </div>
      </form>
    </b-modal>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");
export default {
  name: "DataTable",
  data() {
    return {
      options: [
        { id: 1, value: null, text: "Please select an option" },
        { id: 2, value: "a", text: "This is First option" },
        { id: 3, value: "b", text: "Selected Option" },
        {
          id: 4,
          value: { C: "3PO" },
          text: "This is an option with object value",
        },
        { id: 5, value: "d", text: "This one is disabled", disabled: true },
      ],
      tableName: "Persona",
      listaItems: [],
      persona: {},
      empresaId: "",
      listaPersonaUbicacion: [],
      infoForPersonData: {
        nombres: null,
        segundoNombre: null,
        apellidos: null,
        segundoApellido: null,
        tipoDoc: null,
        doc: null,
        estadoCivil: null,
        direccion: null,
        municipio: null,
        telFijo: null,
        telMovil: null,
        correo: null,
        empresa: null,
        rol: null,
        sucursal: null,
        area: null,
      },
      dataIngresoRetiro: {},
      selected: null,
      listaCausalRetiro: [],
      NPerFechaIngRet: [],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    await this.getData();
    await this.getPersonaUbicacion();
    await this.obtenerListaCausalRetiro();
    core.index();
    this.dataTableInstance = window.$("#datatable_persona").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    async GetNPerFechaIngRetByPersonaId(PersonaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NPerFechaIngRet/GetNPerFechaIngRetByPersonaId/" + PersonaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.NPerFechaIngRet = res;
        } else {
          this.NPerFechaIngRet = [];
        }
      } catch (error) {
        this.NPerFechaIngRet = [];
        console.log("err", error);
      }
    },
    async obtenerListaCausalRetiro() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PerCausalRetiro/GetPerCausalRetiro",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaCausalRetiro = res;
        } else {
          this.listaCausalRetiro = [];
        }
      } catch (error) {
        this.listaCausalRetiro = [];
        console.log("err", error);
      }
    },
    validarYEnviarFormulario() {
      // Usamos el método nativo reportValidity para validar el formulario
      const form = this.$refs.formIngresoRetiro;
      if (form.reportValidity()) {
        // Si el formulario es válido, llamamos a la función de envío
        this.guardarIngresoRetiro();
      }
    },
    async guardarIngresoRetiro() {
      try {
        let dataIngresoRetiro = {
          FechaEvento: this.$moment(new Date())
            .tz("America/Bogota")
            .format("YYYY-MM-DDTHH:mm"),
          Fecha: this.$moment(this.dataIngresoRetiro.fecha)
            .tz("America/Bogota")
            .format("YYYY-MM-DDTHH:mm"),
          PerCausalRetiroId: this.selected,
          PersonaId: this.infoForPersonData.personaId,
          Tipo:
            this.infoForPersonData.fechaRetiro == null ? "Retiro" : "Ingreso",
        };
        await this.$store.dispatch("hl_post", {
          path: "NPerFechaIngRet/CreateNPerFechaIngRet",
          data: dataIngresoRetiro,
        });
        this.$bvModal.hide("modal-retiro");
        await this.getData();
        await this.getPersonaUbicacion();

        this.$toast.success("Registro almacenado correctamente", {
          position: "top-right",
          timeout: 2984,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        alert(error);
      }
    },
    gotoRegistroNovedades(it) {
      console.log(it);
      if (it.salario == null || it.salario == 0) {
        Swal.fire("Error", "Empleado no tiene salario asignado", "Error");
      } else {
        let nominaPath = this.$route.path.includes("nomina");
        if (nominaPath) {
          this.$router.push({
            name: "PersonaNovedades",
            params: { Id: it.id },
          });
        } else {
          this.$router.push("RegistroNovedades/" + it.id);
        }
      }
    },
    async infoForPerson(data) {
      console.log(data);
      this.infoForPersonData = {};
      this.infoForPersonData.nombres = data.nombres;
      this.infoForPersonData.segundoNombre = data.segundoNombre;
      this.infoForPersonData.apellidos = data.apellidos;
      this.infoForPersonData.segundoApellido = data.segundoApellido;
      this.infoForPersonData.tipoDoc = data.tipoDocumento.descripcion;
      this.infoForPersonData.doc = data.numeroDocumento;
      this.infoForPersonData.estadoCivil = data.estadoCivil.descripcion;
      this.infoForPersonData.direccion = data.direccion;
      this.infoForPersonData.municipio = data.municipio.descripcion;
      this.infoForPersonData.telFijo = data.telefonoFijo;
      this.infoForPersonData.telMovil = data.telefonoMovil;
      this.infoForPersonData.correo = data.correo;
      this.infoForPersonData.rol = data.rol.descripcion;
      this.infoForPersonData.empresa = data.empresa.nombre;
      this.infoForPersonData.fechaContratacion = data.fechaContratacion;
      this.infoForPersonData.fechaRetiro = data.fechaRetiro;
      this.infoForPersonData.personaId = data.id;

      await this.GetNPerFechaIngRetByPersonaId(data.id);
      this.getUbicacionPersonaDatallesModal(data);
    },
    async TogglePersonaEstado(id) {
      Swal.fire({
        title: "Seguro que quiere cambiar el estado?",
        text: "Puede cambiar el estado las veces que lo requiera!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "rgb(242, 19, 67)",
        confirmButtonColor: "#1A5CFF",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cambiar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Persona/TogglePersonaEstado/" + id,
          });
          await this.getData();

          Swal.fire(
            "Listo!",
            "Se ha cambiado el estado correctamente.",
            "success"
          );
        }
      });
    },
    obtenerUbicacionPersona(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      if (io > 0) {
        let result = this.listaPersonaUbicacion.indexOf(io);
        console.log("cargo ", result);
      }
      return io ? io.cargo.nombre : "";
    },
    getUbicacionPersonaDatallesModal(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      // console.log("cargo detalle ", io);
      this.infoForPersonData.sucursal = io.sucursal.descripcion;
      this.infoForPersonData.area = io.area.descripcion;
    },
    async getPersonaUbicacion() {
      try {
        //   console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/List/" + this.empresaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaPersonaUbicacion = res;
        } else {
          this.listaPersonaUbicacion = [];
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },
    async getData() {
      console.log(this.persona.empresa.id);
      try {
        console.log("get ListPersonaAll...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersonaAll/" + this.empresaId,
        });
        console.log("ListPersonaAll...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err ListPersonaAll", error);
      }
    },
  },
};
</script>

<style>
/* tooltip  */
.font_tooltip {
  font-size: 14px;
  font-style: italic;
}
</style>
